import { createStore } from 'vuex'
import axios from 'axios'
import store from './index';

//tecnico recursos humanos y tecnico comercio exterior se consideran en ing rrhh e ing comex
// fin configuracion de sockets
export default createStore({
  state: {
    logged:false,
    clicked:false,
    usernameModalClicked:false,
    loginUsername:'',
    loginPassword:'',
    usuario: false,
    emailError:false,
    registerSuccess:false,
    userNotVerified:false,
    token:localStorage.getItem("token") || false,
  },
  getters: {
  },
  mutations: {
   goToHome:()=>{
    this.$router.push('homeView')
    },
    isClicked:async(state)=>{
          state.clicked=true
    },
    REMOVE_DATA(){
      localStorage.removeItem("token")
    },
    SET_DATA(state,data) { 
      state.logged=true
      localStorage.setItem("token",data.token) 
      state.usuario=data.usuario
}
},
  actions: {
 
      setData({ commit }, data) {
        commit('REMOVE_DATA');
        console.log("setDATA ACTION: ESTE ES EL USUARIO Y EL TOKEN",data.token," ",data.usuario)
        commit('SET_DATA', data);
      },
      cerrarSesion(){
        localStorage.clear()
        window.location.href="https://profe-rodri.com/"
      },
  
  },
  modules: {
  }
})