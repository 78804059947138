<template>
<div id="desktopNav">
  <div v-if="$store.state.logged == false" class="navOptions1">
<div class="optionsBox1">
  <router-link to="/ingresar" class="enlace" active-class="activo1" exact-active-class="exacto">ingresar</router-link>
</div>
 </div>
 <div v-if="$store.state.logged == true" class="navOptions1">
<div class="optionsBox1">
  <router-link to="/miPerfil" class="enlace" active-class="activo1" exact-active-class="exacto">Mi perfil</router-link>
  <a class="enlace" v-on:click="$store.dispatch('cerrarSesion')">Cerrar sesión</a>
</div>
 </div>
 <div class="userData">
  <img src="../assets/la-classroom-de-rodrigo.png" alt="">
  
  <div  id="usuario">
      <h3 id="usuarioDesconectado" v-if="$store.state.logged !=true">¡Hola estudiante! bienvenido a profe-rodri.com</h3>
      <h3 id="usuarioDesconectado" v-if="$store.state.logged ==true">¡Bienvenido {{ $store.state.usuario }}!</h3>
  </div>

 </div>
 <div class="navOptions2">
  <router-link to="/" class="enlace2" active-class="activo2" exact-active-class="exacto">Inicio</router-link>
  <router-link to="/cursos" class="enlace2" active-class="activo2" exact-active-class="exacto">Cursos</router-link>
  <router-link to="/profesor" class="enlace2" active-class="activo2" exact-active-class="exacto">Sobre mí</router-link>
  <router-link to="/misClases" v-if="$store.state.logged==true" class="enlace2" active-class="activo2" exact-active-class="exacto">Mis clases</router-link>
   <router-link to="/ayuda" class="enlace2" active-class="activo2" exact-active-class="exacto">preguntas frecuentes</router-link>
 </div>
</div>

</template>
<script>
import axios from 'axios'
import {io} from 'socket.io-client'

import modal from '@/components/modal.vue'



export default {
      name: 'desktopNav',
      components: {
       modal
  },
  data(){
        return{
          message: "el socket no ha funcionado",
          times:[],
          encuestasRespondidas:0,
          usuario:localStorage.getItem("usuario") || false,
          token:localStorage.getItem("token") || false,
        }
      },
  mounted() {
//hacer validacion de la data del localstorage
  },
  beforeDestroy() {
    
  },methods:{
  closeMobileNav:()=>{
    document.querySelector("#navbarNav").classList.toggle("show")
    },

      
    }


     }
</script>
<style scoped>
/* 
ESTILOS:
font-family: 'Lexend Peta', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Russo One', sans-serif;
*/

#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

}


li h1{
  font-size: large;
}
img{
  animation: spin 8s infinite linear;
  height: 28vh;
}
@keyframes spin {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
#serviciosDropdown{
  font-size:3vh;
  margin-top: 3vh;
}

#desktopNav{
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      top: 0;
      left: 0;
      height: 100vh;
      min-width:20vw;
      max-width:20vw;
      background-color: #fff; 
    }
    .dropdown{
      display: flex;
      justify-content: center;
      align-items: center;
      width:20vw;
    }
.userData img{
  margin:2vh
}
.nav div{

  background-color:#fff;
}
#mobileNav{
  display: none;
  
}
#desktopNav ul{
  width: 100%;
  text-align: center;
}

#desktopNav div{
max-width: 100%;

}
#desktopNav h2{
max-width: 20vw;
word-wrap: break-word;
}


#desktopNav img{
max-width: 20vw;
}

.enlace{
color: #fff;
font-size: 2vh;
text-decoration: none;
cursor: pointer;
background-color:#f00;
}
.enlace2{
  cursor: pointer;
color: #fff;
font-size: 5vh;
text-decoration: none;
}
.userData{
  height: 50vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  
}
.userData img{
  height: 30vh;
  width: 30vh;
}
div a{
  width: 20vw;
}
.userData h1{
font-size: x-large;
text-align: center;
}
.navOptions2{
  height: 50vh; 
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.navOptions1{
  height: min-content; 
  background-color: #f00;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
flex-wrap: wrap;
  align-items: center;
  font-size:4vh;
}
.navOptions1 .enlace{
  height: min-content; 
  background-color: #f00;
  display: flex;
  flex-direction: row;
  justify-content:center;
  margin:2vh ;
flex-wrap: wrap;
  align-items: center;
  font-size:2vh;
}
.navOptions1 div {
  height: 9vh; 
overflow: hidden;
}

.activo1{
  font-size:2vh;
  color: #fff;
  background-color: #f00;
  text-decoration:underline;
}
.activo2{
  font-size: 5vh;
  color: #fff;
  background-color: #f00;

animation:apear 2s ease-in-out;
}
.optionsBox1{
  height: 9vh; 
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 20vw;
  border: 3px solid  #f00;
  background-color: #f00;
  color: #fff;
}

.optionsBox2{
  height: 50vh; 
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 20vw;
  background-color: transparent;
  color: #fff;
}

@keyframes apear {
  0% {opacity: 0; }
  100% {opacity: 2;  }
}
/*subirlo de 980 haria tener problemas al mobilenav*/
@media (max-width:980px) {
  #mobileNav{
  display: flex;
  background-color: #fff;
}
#desktopNav{
  display: none;
}

}
#usuario h1,h3{
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size:20px ;
}

.mensaje{
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size:20px ;
}
</style>

