<template>
  <div class="locationDiv"><h1>inicio</h1></div>
  <div id="homeWrapperNotLogged">
    <div class="welcome">
        <h1>Querido estudiante ¿En que puedo ayudarte hoy?</h1>
    </div>
    <div class="wrapper">  
        <div class="container" v-on:click="goTo('/ingresar')">  
          <img class="optionImage" src="../assets/iniciar-sesion.png">
          <h2>Ya compré un curso</h2>
          <p>inicia sesión en tu cuenta aquí</p>
        </div>
        <div class="container" v-on:click="goTo('/cursos')">   
          <img class="optionImage" src="../assets/adquirir.png">
          <h2>quiero adquirir un curso</h2>
          <p>¡Haz click aquí para adquirir tu curso presencial y personalizado!</p>
        </div>
        <div class="container"  v-on:click="goTo('/ayuda')">   
          <img class="optionImage" src="../assets/dudas.png">
          <h2>Soluciona tus dudas</h2>
          <p>Puedes ver las preguntas frecuentes aquí o contactarme por privado a mi correo rodrigomreidenbach@gmail.com</p>
       </div>
      </div>
  </div>
 </template>
     
     <script>
     // @ is an alias to /src

     import store from '../store/index'

     export default {
       name: 'HomeView',
       components: {
     
       },data(){
         return{
           message: "el socket no ha funcionado",
           times:[]
         }
       },
       mounted() {
     
     },
     methods:{
      goTo(to){
this.$router.push(to)
      }
     }
     }
     </script>
 <style scoped>

 .locationDiv{
  width: 80vw;
  height: 9vh;
  margin-left: 20vw;
  background-color:#f00 ;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px; /*The space between grid containers*/
  grid-auto-rows:80vh;
  padding: 5px 0;
  max-width: 100%;
 overflow-x: hidden;
}
.container{
  display: flex;
  flex-direction: column ;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff0000;
background-color: #fff;
cursor: pointer;
overflow: hidden;
width: 90%;

}
.container:hover{
  background-color: #f00;
  color:#fff;
  transition: 0.3s;
}
 #homeWrapperNotLogged{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color:#fff; 
 color: #000;
 height: min-content;
 width: 80vw;
 text-align: center;
 justify-content: center;
 margin-left: 20vw;
 }
 .welcome{
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  height:22vh;
  color:#f00;
 }

 .row{
   display: flex;
 }
 form input{
 width: 20vw;
 }

 .optionImage{
  width: 200px;
  height:100px;
 }
@media (max-width: 980px) {

  .optionImage{
  width:100px;
  height:50px;
 }
 
#homeWrapperNotLogged{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color:#fff; 
 color: #000;
 height: min-content;
 width: 100%;
 text-align: center;
 justify-content: center;
 margin-left: 0;
 overflow:hidden;
 }
.locationDiv
{
  width: 100%;
  height: 9vh;
  margin-left: 0;
  background-color: #f00;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
 }
 .wrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px; /*The space between grid containers*/
  grid-auto-rows:minmax(50vh,50vh);
  padding:   5px 0px;
  max-width: 100vw;
 
}
  }
  @media(max-height:300px){
     .welcome{

         height:120px;

      }
  }
 
 </style>
