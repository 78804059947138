import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
let routes =[
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
 // {
 //   path: '/about',
 //   name: 'about',
 //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
 // },
  {
    path: '/ingresar',
    name: 'ingresar',
    component: () => import(/* webpackChunkName: "about" */ '../views/ingresar.vue')
  },
  {
    path: '/miPerfil',
    name: 'miperfil',
    component: () => import(/* webpackChunkName: "about" */ '../views/miPerfil.vue')
  },
  {
    path: '/misClases',
    name: 'misclases',
    component: () => import(/* webpackChunkName: "about" */ '../views/misClases.vue')
  },
  {
    path: '/cursos',
    name: 'cursos',
    component: () => import(/* webpackChunkName: "about" */ '../views/cursos.vue')
  },
  {
    path: '/profesor',
    name: 'add',
    component: () => import(/* webpackChunkName: "about" */ '../views/profesor.vue')
  },
  {
    path: '/curso-software/temario',
    name: '',
    component: () => import(/* webpackChunkName: "about" */ '../views/curso-software-temario.vue')
  },
  {
    path: '/curso-criptomonedas/temario',
    name: 'u',
    component: () => import(/* webpackChunkName: "about" */ '../views/curso-criptomonedas-temario.vue')
  },

  {
    path: '/curso-software',
    name: 'a',
    component: () => import(/* webpackChunkName: "about" */ '../views/curso-software.vue')
  },
  {
    path: '/curso-criptomonedas',
    name: 'curos',
    component: () => import(/* webpackChunkName: "about" */ '../views/curso-criptomonedas.vue')
  },
  {
    path: '/ayuda',
    name: 'ayuda',
    component: () => import(/* webpackChunkName: "about" */ '../views/ayuda.vue')
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
//despues se hacer esto, importar 
export default router
